<template>
<div class="tgju-widget light post" style="box-shadow: none">
    <LoginRegister :is_comment_component="data.is_comment_component"></LoginRegister>
</div>
</template>

<style lang="scss">
.user-block-comment-items ul li.like {
    position: relative;
    top: 3px;
    float: none;
}

.user-block-comment-item-content .emoji {
    width: 17px !important;
    margin-right: 3px !important;
}

.user-block-comment-item-content .post-img {
    max-width: 100%;
    margin-top: 20px;
}
</style>

<script>
// این کامپوننت برای نمایش مودال لاگین و ثبت نام در بخش سیستم کامنت استفاده میشود 
// وضعیت فعلی : غیرفعال
// در حال  استفاده از auth.js
import LoginRegister from '@/components/LoginRegister.vue'

export default {
    name: 'TgjuPostLoginRegisterModal',
    props: ['data'],
    components: {
        LoginRegister,
    },
    data: function () {
        return {}
    },
    computed: {},
    mounted() {
        this.$root.$on('CloseLoginModal', (data) => {
            this.$parent.closeModal();
        });
    },
    methods: {
        method(container) {},
    },
}
</script>
