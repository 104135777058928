<template>
<div class="tgju-widgets-block col-12 widget-border-fix">

    <img class="auth-logo" src="https://www.tgju.org/touch-icon-ipad.png?v=03" alt="TGJU">
    <div class="auth-content">
        برای مشاهده اطلاعات این بخش از سایت ابتدا باید وارد حساب کاربری خود شوید.
    </div>
    <button type="button" class="m-2  btn btn-primary w-100" @click="openAuthModal">
        ورود - عضویت
    </button>
    <!-- openAuthModal -->
    <!-- start auth_entry_step -->
    <!-- <div v-show="auth_entry_step" class="col-12">

            <div class="col-12 text-center">

            شماره موبایل خود را در فیلد پایین وارد نمایید

            </div>

            <div class="col-12">

            <input type="text" class="m-2 text-center" v-model="mobile" placeholder="09123456789">

            </div>

            <div class="col-12">

            <button type="button" class="m-2  btn btn-primary w-100" @click="doAuth" :disabled="loading"> 

            تایید 

            <span v-show="loading" class="spinner-border spinner-border-sm" role="status"></span>

            </button>

            </div>

            </div> -->
                <!-- end auth_entry_step -->

                <!-- start otp_step -->
                <!-- <div v-show="otp_step" class="col-12 p-2 text-center">

            کد امنیتی دریافت کرده را در فیلد پایین وارد کنید 

            <div class="col-12">

            <input type="text" class="m-2 text-center" v-model="code" placeholder="کد امنیتی">

            </div>

            <div class="col-12">

            <button type="button" class="btn btn-primary btn-sm m-2 w-100" @click="checkOtp" :disabled="loading">

            بررسی

            <span v-show="loading" class="spinner-border spinner-border-sm" role="status"></span>

            </button>

            <button type="button" class="btn btn-secondary btn-sm m-2 w-100" @click="goStep('auth_entry_step')"> بازگشت </button>

            </div>

            </div> -->
                <!-- end otp_step -->

                <!-- start register_step -->
                <!-- <div v-show="register_step" class="col-12 text-center">

            لطفا مشخصات خود را در جهت عضویت اولیه در سامانه وارد نمایید.

            <div class="col-sm-12  p-2 m-2">

            <input type="text" v-model="username" placeholder="نام کاربری : فقط کاراکترهای انگلیسی، اعداد و زیرخط">

            </div>

            <div class="col-sm-12  p-2 m-2">

            <input type="email" v-model="email" placeholder="ایمیل">

            </div>

            <div class="col-sm-12  p-2 m-2">

            <input type="text" v-model="fname" placeholder="نام">

            </div>

            <div class="col-sm-12  p-2 m-2">

            <input type="text" v-model="lname" placeholder="نام خانوادگی">

            </div>

            <div class="col-sm-12 text-center">

            <button type="button" class="btn btn-primary btn-sm m-2 w-100" @click="doRegister" :disabled="loading">

            بررسی

            <span v-show="loading" class="spinner-border spinner-border-sm" role="status"></span>

            </button>

            </div>

        </div> -->
    <!-- end otp_step -->
</div>
</template>

<style>
    .auth-modal-close {
        display: none;
    }
    #auth-modal-box .auth-inner {
        position: absolute !important;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: auto !important;
        max-width: unset !important;
        border-radius: 0 !important;
    }
    html,
    body{
        overflow: hidden;
    }
    .auth-cover{
        overflow: hidden;
        position: relative;
    }
    .auth-cover:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 1;
        opacity: 1;
        background: linear-gradient(180deg,rgb(255 175 70/0%),rgb(25 25 25) 100%);
    }
    .cover-flex-box-items {
        position: absolute;
        z-index: 5;
        bottom: 100px;
        padding: 0 100px;
    }
    .cover-flex-box-items h3 {
        font-size: 28px;
        font-weight: 600;
        margin: 0;
        padding: 10px 0 0;
        color: #fff;
        text-shadow: 1px 2px 3px #1f1f1f;
    }
    .cover-flex-box-items p {
        color: #fff;
        text-align: justify;
        font-size: 15px;
        line-height: 2;
    }
    .cover-flex-box-items ul {
        margin: 0;
        padding: 0;
        display: block;
        margin-top: 20px;
    }
    .cover-flex-box-items ul li {
        display: inline-block;
    }
    .cover-flex-box-items ul li a {
        padding: 0 0 0 15px;
        color: #f0bc5e;
        font-size: 14px;
    }
    .cover-flex-box-items ul li a:hover {
        color: #b8914a;
    }
    .swal-overlay.swal-overlay--show-modal {
        background: #fff;
    }
    .swal-footer {
     text-align: center;
    }
    #footer {
        display: none !important;
    }
    #main-header {
        display: none !important;
    }
    #main {
        display: none !important;
    }
    .footer-quickview {
        display: none !important;
    }
</style>

<script>
// این کاپونتت اجزای صفحه لاگین را در خود جای میدهد
// این بخش دارای دو بخش پیاد سازی شده می باشد . 
// بخش ویو که فعلا در حالت غیرفعال می باشد و بیشتر متد های آن استفاده نمیشود .
// بخش لاگین و هویت سنجی یونیورسال که از فایل زیر کمک گرفته میشود 
// auth.js
export default {
    name: "LoginRegister",
    props: ['is_comment_component'],
    components: {},
    data: function () {
        return {
            loading: false,
            //-------------
            mobile: undefined,
            code: undefined,
            //------------
            username: undefined,
            email: undefined,
            fname: undefined,
            lname: undefined,

            // step data
            auth_entry_step: true,
            otp_step: false,
            register_step: false,
        };
    },
    mounted() {
        this.openAuthModal();
    },
    methods: {
        doAuth() {
            /**
             * بررسی شماره موبایل
             */
            if (!this.mobile) {
                Vue.prototype.$toast.error({
                    title: 'خطا',
                    message: 'فیلد موبایل الزامی است'
                });
                return 0;
            }

            /**
             * آماده سازی دیتای درخواست ای پی آی
             */
            let api_data = {
                mobile: this.mobile,
            };

            // فعال سازی لودینگ
            this.loading = true;

            /**
             * ارسال درخواست ای پی آی
             */
            this.$helpers.makeRequest('POST', '/auth/init', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);
                // غیرفعال سازی لودینگ
                this.loading = false;

                /**
                 * در صورتی در خواست ای پی آی خطایی داشت پیام مناسب نمایش دهد
                 */
                if (api_response.data.status === 'error') {
                    Vue.prototype.$toast.error({
                        title: 'خطا',
                        message: api_response.data.message === 'wrong_mobile' ? 'شماره موبایل اشتباه است' : 'خطا در ارسال کد امنیتی',
                    });
                    return 0;
                }

                /**
                 * در صورتی که در خواست ای پی آی موفقیت آمیز بود
                 */
                if (api_response.data.status === 'success') {
                    Vue.prototype.$toast.success({
                        title: 'موفق',
                        message: 'کد تایید با موفقیت ارسال شد',
                    });

                    /**
                     * نمایش گام ورود کد فعال سازی
                     */
                    this.goStep('otp_step');
                }
            });

        },
        checkOtp() {
            /**
             * بررسی کد امنیتی
             */
            if (!this.code) {
                Vue.prototype.$toast.error({
                    title: 'خطا',
                    message: 'فیلد کد امنیتی الزامی است'
                });
                return 0;
            }

            /**
             * آماده سازی دیتای درخواست ای پی آی
             */
            let api_data = {
                mobile: this.mobile,
                code: this.code,
            };

            // فعال سازی لودینگ
            this.loading = true;

            /**
             * ارسال درخواست ای پی آی
             */
            this.$helpers.makeRequest('POST', '/auth/otp', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                /**
                 * در صورتی در خواست ای پی آی خطایی داشت پیام مناسب نمایش دهد
                 */
                if (api_response.data.status === 'error') {
                    // غیرفعال سازی لودینگ
                    this.loading = false;

                    Vue.prototype.$toast.error({
                        title: 'خطا',
                        message: 'کد امنیتی معتبر نمی باشد',
                    });
                    return 0;
                }

                /**
                 * در صورتی که در خواست ای پی آی موفقیت آمیز بود
                 */
                if (api_response.data.status === 'success') {
                    // در صورتی که پیام ورود کاربر از طرف ای پی آی صادر شد
                    if (api_response.data.message === 'logged_in') {
                        this.$store.commit('setAccessToken', api_response.data.token);
                        this.$store.dispatch('setUserData')
                            .then(response => {
                                // سینک کردن منو های داشبورد از دیتابیس
                                this.$store.dispatch('setDashboards', { forced: true });
                                // ثبت کد پوش نوتیفیکیشن برای کاربر
                                this.$store.dispatch('setFirebaseToken', { forced: true });
                                //---------------
                                if (this.is_comment_component) {
                                    this.$root.$emit('LoginCallBack', 'Done');
                                    this.$root.$emit('CloseLoginModal', 'Done');
                                } else {
                                    this.$router.push(this.$route.query.redirect || { name: "Profile" })
                                }
                            });
                        return 0;
                    }

                    // در صورتی که پیام ثبت نام کاربر از طرف ای پی آی صادر شد
                    if (api_response.data.message === 'register') {
                        this.loading = false;
                        this.goStep('register_step');
                    }
                }
            });
        },
        doRegister() {
            /**
             * آرایه ای از خطا های رایج
             * */
            let server_response = {
                field_empty: 'فیلد ها الزامی می باشند',
                email_format_error: 'ساختار ایمیل صحیح نمی باشد',
                otp_error: 'کد امنیتی مشکل دارد',
                mobile_exists: 'شماره موبایل تکرار است',
                email_exists: 'ایمیل تکراری است',
                username_exists: 'نام کاربری تکرار است',
                username_format_error: 'ساختار نام کاربری صحیح نمی باشد',
                register_error: 'مشکلی در ثبت نام روند ثبت نام بوجود آمده است',
            };

            /**
             * بررسی تکمیل بود فیلد ها
             */
            if (!this.fname || !this.lname || !this.email || !this.username) {
                Vue.prototype.$toast.error({
                    title: 'خطا',
                    message: 'فیلد ها الزامی هستند'
                });
                return 0;
            }

            /**
             * آماده سازی دیتای درخواست ای پی آی
             */
            let api_data = {
                mobile: this.mobile,
                code: this.code,
                firstname: this.fname,
                lastname: this.lname,
                email: this.email,
                username: this.username,
            };

            // فعال سازی لودینگ
            this.loading = true;

            /**
             * ارسال درخواست ای پی آی
             */
            this.$helpers.makeRequest('POST', '/auth/register', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                /**
                 * در صورتی در خواست ای پی آی خطایی داشت پیام مناسب نمایش دهد
                 */
                if (api_response.data.status === 'error') {
                    // غیرفعال سازی لودینگ
                    this.loading = false;

                    Vue.prototype.$toast.error({
                        title: 'خطا',
                        message: server_response[api_response.data.message],
                    });
                    return 0;
                }

                /**
                 * در صورتی که در خواست ای پی آی موفقیت آمیز بود
                 */
                if (api_response.data.status === 'success') {
                    Vue.prototype.$toast.success({
                        title: 'موفق',
                        message: "ثبت نام موفق بود",
                    });

                    this.$store.commit('setAccessToken', api_response.data.token);
                    this.$store.dispatch('setUserData')
                        .then(response => {
                            // سینک کردن منو های داشبورد از دیتابیس
                            this.$store.dispatch('setDashboards', { forced: true });
                            // ثبت کد پوش نوتیفیکیشن برای کاربر
                            this.$store.dispatch('setFirebaseToken', { forced: true });
                            //---------------
                            if (this.is_comment_component) {
                                this.$root.$emit('LoginCallBack', 'Done');
                                this.$root.$emit('CloseLoginModal', 'Done');
                            } else {
                                this.$router.push(this.$route.query.redirect || { name: "Profile" })
                            }
                        });
                    return 0;
                }
            });
        },
        goStep(which) {
            this.auth_entry_step = (which == 'auth_entry_step' ? true : false);
            this.otp_step = (which == 'otp_step' ? true : false);
            this.register_step = (which == 'register_step' ? true : false);
        },
        // این متد هندل باز کردن مودال ثبت و لاگین را بر عهده دارد /
        openAuthModal() {
            if ($('#auth-modal').length) {
                $('#auth-modal').toggle();
            } else {
                window.Auth.set_api_domain(process.env.VUE_APP_API_URL);
                window.Auth.set_frontend_app_sso_login(true);
                window.Auth.load();
            }
            
            var cover_data = '';
            cover_data += '<div class="cover-flex-box-items">';
            cover_data += '<h3> پنل کاربری </h3>';
            cover_data += '<p>';
            cover_data += 'پنل کاربری مجموعه ای از ابزارهای کاربری، تحلیلی و تعاملی است که امکان چینش، پردازش و استفاده بدون محدودیت از کلیه داده های سایت را برای کاربران و ابزارهای موثر و کارآمد برای سرمایه گذاری هر چه سودبخش تر برای سرمایه گذاران فراهم می سازد';
            cover_data += '</br>';
            cover_data += 'همچنین این پنل، طیف متنوعی از نمودارها، ابزارهای تکنیکال و دیگر موارد مورد نیاز تحلیل گران را در کنار بستری تخصصی برای ارایه تحلیل ها و سنجش بازخورد و نتایج آنها مهیا نموده است و علاوه بر تمام این موارد، کاربران محترم را قادر می سازد که عضو بزرگترین شبکه اجتماعی و ارتباطی بازارهای مالی ایران شده و علاوه بر استفاده از آرا، تحلیل ها و تخصص دیگر کاربران، مجموعه ای از روابط جدید و حرفه ای را برای خود ایجاد نمایند';
            cover_data += ' </p>';
            cover_data += ' <ul class="hide-in-app">';
            cover_data += ' <li><a href="https://www.tgju.org/">صفحه اصلی</a></li>';
            cover_data += ' <li><a href="https://www.tgju.org/panel/profile">نمایه کاربر</a></li>';
            cover_data += ' <li><a href="https://www.tgju.org/panel/profile/edit">حساب کاربری</a></li>';
            cover_data += ' <li><a href="https://www.tgju.org/panel/technicals">تابلو تحلیل‌ها</a></li>';
            cover_data += ' <li><a href="https://www.tgju.org/panel/forums">گفت و گوها</a></li>';
            cover_data += ' <li><a href="https://www.tgju.org/contact">تماس با ما</a></li>';
            cover_data += '</ul>';
            cover_data += ' </div>';

            $('.auth-cover').html(cover_data);
        },
    },
};
</script>
